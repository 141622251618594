import React, { FC } from 'react'

import { ModalProps } from '../Modal'
import { ActionButtonProps } from '../ActionButton'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type LoginModalSectionProps = {
  title?: string
  subtitle?: string
  button?: ActionButtonProps
  ecpsConnectionButton?: ActionButtonProps
  helpText?: string
  ecpsConnectionIssueLink?: LinkProps
}

export type LoginModalProps = ModalProps & {
  login?: LoginModalSectionProps
  register?: LoginModalSectionProps
}

const LoginModal: FC<LoginModalProps> = ({
  login,
  register,
  ...modalProps
}) => {
  return (
    <SC.LoginModal {...modalProps}>
      <SC.Sections>
        {login && (
          <SC.Section>
            <SC.Title>{login.title}</SC.Title>
            {login.subtitle && <SC.Subtitle>{login.subtitle}</SC.Subtitle>}
            <SC.Wrapper>
              <SC.Button {...login.ecpsConnectionButton} />
              <SC.Button {...login.button} />
            </SC.Wrapper>
            <SC.HelpText>{login.helpText}</SC.HelpText>
            <SC.EcpsConnectionIssueLink {...login.ecpsConnectionIssueLink} />
          </SC.Section>
        )}
        {register && (
          <SC.Section>
            <SC.Title>{register.title}</SC.Title>
            {register.subtitle && (
              <SC.Subtitle>{register.subtitle}</SC.Subtitle>
            )}
            <SC.Button {...register.button} />
          </SC.Section>
        )}
      </SC.Sections>
    </SC.LoginModal>
  )
}

export default LoginModal
