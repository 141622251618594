import React, { FC } from 'react'

import * as SC from './styled'

export type HtmlProps = {
  className?: string
  text?: string
  children?: string
}

const Html: FC<HtmlProps> = ({ className, text, children }) => {
  return text || children ? (
    <SC.Html
      className={className}
      dangerouslySetInnerHTML={{ __html: (text || children) ?? '' }}
    />
  ) : null
}

export default Html
