import styled from 'styled-components'

import { mq, sizes } from '../../theme'
import HeaderBis from '../../components/HeaderBis'

export const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  @supports (min-height: 100svh) {
    min-height: 100svh;
  }
`
export const StyledHeader = styled(HeaderBis)``
export const Content = styled.main<{ $withMobileModal?: boolean }>`
  position: relative;
  z-index: ${({ theme, $withMobileModal }) =>
    $withMobileModal ? theme.zIndexes.modal : theme.zIndexes.background};
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @media ${mq(sizes.tablet)} {
    z-index: ${({ theme }) => theme.zIndexes.background};
  }
`

export const Modals = styled.div``
