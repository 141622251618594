import styled, { css } from 'styled-components'

import BoxedContainer from '../BoxedContainer'
import SectionSpacer from '../SectionSpacer'

export const Section = styled.section<{ $withBackgroundColor?: boolean }>`
  ${({ theme, $withBackgroundColor }) =>
    $withBackgroundColor &&
    css`
      background-color: ${theme.colors.blueBack};
    `}
`
export const Wrapper = styled(BoxedContainer)``
export const Spacer = styled(SectionSpacer)``
