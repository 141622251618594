import React, { FC } from 'react'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type BreadcrumbProps = {
  className?: string
  items?: Array<LinkProps>
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items, className }) => {
  return (
    <SC.BreadcrumbList className={className}>
      {items &&
        items.map((item, index) => (
          <SC.BreadcrumbListItem key={index}>
            <SC.Lk {...item} />
          </SC.BreadcrumbListItem>
        ))}
    </SC.BreadcrumbList>
  )
}

export default Breadcrumb
