import { useTranslation } from 'react-i18next'

import { BreadcrumbProps } from '../../components/Breadcrumb'
import type { Page } from '../../rest/types/Page'

const useBreadcrumbProps = (data: Page): BreadcrumbProps | undefined => {
  const { t } = useTranslation()
  return data.slug !== t('homePageSlug') &&
    data.slug !== t('adsHomePageSlug') &&
    data.slug !== t('centralHomePageSlug')
    ? {
        items: [
          {
            text: t('home_breadcrumb_label'),
            href:
              data.slug === t('form_slug_request_access_central') ||
              data.slug === t('unlisted_product_form_slug')
                ? t('centralHomePageLink')
                : '/',
          },
          {
            text: data.name,
          },
        ],
      }
    : undefined
}

export default useBreadcrumbProps
