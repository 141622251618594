import styled, { css } from 'styled-components'

import { mq, sizes } from '../../theme'

import type { SectionSpacerProps } from './index'

export const SectionSpacer = styled.div<{
  $spacing: SectionSpacerProps['spacing']
}>`
  @media ${mq(null, sizes.tablet)} {
    ${(props) =>
      props.$spacing === 'medium' &&
      css`
        margin: 2.5rem auto;
      `}

    ${(props) =>
      props.$spacing === 'small' &&
      css`
        margin: 1.2rem auto;
      `}

    ${(props) =>
      props.$spacing === 'large' &&
      css`
        margin: 4rem auto;
      `}

    ${(props) =>
      props.$spacing === 'big' &&
      css`
        margin: 6rem auto;
      `}
  }

  @media ${mq(sizes.tablet)} {
    ${(props) =>
      props.$spacing === 'medium' &&
      css`
        margin: 3rem auto;
      `}

    ${(props) =>
      props.$spacing === 'small' &&
      css`
        margin: 1.2rem auto;
      `}

    ${(props) =>
      props.$spacing === 'large' &&
      css`
        margin: 4rem auto;
      `}

    ${(props) =>
      props.$spacing === 'big' &&
      css`
        margin: 6rem auto;
      `}
  }

  @media ${mq(sizes.desktop)} {
    ${(props) =>
      props.$spacing === 'medium' &&
      css`
        margin: 4rem auto;
      `}

    ${(props) =>
      props.$spacing === 'small' &&
      css`
        margin: 2.5rem auto;
      `}

    ${(props) =>
      props.$spacing === 'large' &&
      css`
        margin: 8rem auto;
      `}

    ${(props) =>
      props.$spacing === 'big' &&
      css`
        margin: 10rem auto;
      `}
  }
`
