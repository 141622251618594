import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../Link'
import { Icons } from '../Icon'

import * as SC from './styled'

export type BannerInfoProps = {
  className?: string
  text?: string
  link: LinkProps
  color?: string
}

const BannerInfo: FC<BannerInfoProps> = ({ className, text, link, color }) => {
  return (
    <SC.Banner className={cx('BannerInfo', className)} $color={color}>
      {text && <SC.Text>{text}</SC.Text>}
      <SC.LinkWrapper>
        <SC.Lk {...link}>
          {link.text}
          <SC.SIcon icon={Icons.arrowRight} />
        </SC.Lk>
      </SC.LinkWrapper>
    </SC.Banner>
  )
}

export default BannerInfo
