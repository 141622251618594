import { Configuration } from '../../rest/types/Configuration'
import {
  ClassifiedCategories,
  ClassifiedSpecialties,
} from '../../rest/types/Classified'

import { TitleTextImageProps } from './TitleTextImage/props'
import { ActionsProps } from './Actions/props'
import { PublishAdsProps } from './PublishAds/props'

export type PageBlocksUnion =
  | (TitleTextImageProps & ActionsProps & PublishAdsProps)
  | any

export type BlocksProps = {
  blocks: PageBlocksUnion[]
  configuration: Configuration
  [key: string]: any
}

export type BlockProps<T extends PageBlocksUnion = PageBlocksUnion> = {
  data: T
  configuration: Configuration
  specialities?: ClassifiedSpecialties
  categories?: ClassifiedCategories
  [key: string]: any
}

export enum BlockComponentsName {
  formSearchAds = 'blocks.form-search-ads',
  imageRightTextLinkLeft = 'blocks.image-right-text-link-left',
  highlightedOffers = 'blocks.highlighted-offers',
}
