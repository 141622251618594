import styled from 'styled-components'

import Modal from '../Modal'
import ActionButton from '../ActionButton'
import { mq, sizes } from '../../theme'
import Html from '../Html'
import Link from '../Link'

export const LoginModal = styled(Modal)`
  > div {
    width: 100%;
  }
`

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 0;
  width: 100%;

  @media ${mq(sizes.desktop)} {
    flex-direction: row;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  @media ${mq(sizes.desktop)} {
    width: 50%;
  }
`

export const Title = styled(Html)`
  ${({ theme }) => theme.textStyles.firaSans2XlBold};
  color: ${({ theme }) => theme.colors.bluePrimary};
`

export const Subtitle = styled(Html)`
  ${({ theme }) => theme.textStyles.firaSansXl};
  color: ${({ theme }) => theme.colors.bluePrimary};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.firaSansLg};
  color: ${({ theme }) => theme.colors.bluePrimary};
`

export const HelpText = styled.p`
  margin-top: 3rem;
  ${({ theme }) => theme.textStyles.montserratXl};
  color: ${({ theme }) => theme.colors.blueSecondaryBis};
`

export const EcpsConnectionIssueLink = styled(Link)`
  margin-top: 3.5rem;
  text-decoration: underline;
  ${({ theme }) => theme.textStyles.montserratXl};
  color: ${({ theme }) => theme.colors.blueSecondaryBis};
`

export const Button = styled(ActionButton)`
  margin-top: 1.7rem;
  white-space: nowrap;
`
