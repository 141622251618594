import styled, { css } from 'styled-components'

import { mq, sizes } from '../../theme'
import Link from '../Link'
import Icon from '../Icon'

export const Banner = styled.div<{ $color?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.7rem 3.2rem;
  background-color: ${({ theme }) => theme.colors.greenSecondaryBis};
  border-radius: 10px;
  gap: 1rem;

  ${({ $color }) =>
    $color &&
    css`
      background-color: ${$color};
    `};

  @media ${mq(sizes.tablet)} {
    flex-direction: row;
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.montserratTextBase}
  font-weight: bold;
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  gap: 1rem;
`

export const SIcon = styled(Icon)`
  width: 2rem;
  color: ${({ theme }) => theme.colors.pureWhite};
  margin-left: 1rem;
  transition: transform 320ms ease-in-out;
`

export const Lk = styled(Link)`
  ${({ theme }) => theme.textStyles.montserratTextBase};
  color: ${({ theme }) => theme.colors.pureWhite};
  font-weight: bold;
  width: max-content;

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`
