import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { LoginModalProps } from '../../components/LoginModal'
import { actions, selectors } from '../../redux'
import { api } from '../../configuration'
import { ActionButtonVariant } from '../../components/ActionButton/styled'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'

const useLoginModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isOpen = useSelector(selectors.modals.loginModalIsOpen)
  const isConnected = useSelector(selectors.auth.isConnected)

  const handleCloseModal = () => {
    dispatch(actions.modals.setLoginModalIsOpen(false))
  }

  const props: LoginModalProps = {
    title: t('loginModalTitle'),
    isOpen,
    onClose: handleCloseModal,
    login: {
      title: t('loginModalLoginTitle'),
      subtitle: t('loginModalLoginSubtitle'),
      ecpsConnectionButton: {
        href: `${api.API_URL}/api/connect/${api.AUTH_PROVIDER}`,
        text: t('register_ecps_block_left_button'),
        variant: ActionButtonVariant.quaternary,
      },
      button: {
        ...(isConnected
          ? {
              href: t('form_path_request_access_central'),
              text: t('loginModalRegisterButton'),
            }
          : {
              href: Router.getRouteUrl(routes.loginOrRegister),
              text: t('loginModalLoginByEmailButton'),
            }),
        onClick: handleCloseModal,
      },
      helpText: t('loginModalHelpText'),
      ecpsConnectionIssueLink: {
        label: t('register_ecps_connection_issue_block_left_link_label'),
        href: t('register_ecps_connection_issue_block_left_link_href'),
      },
    },
    register: {
      title: t('loginModalRegisterTitle'),
      subtitle: t('loginModalRegisterSubtitle'),
      button: {
        ...(isConnected
          ? {
              text: t('loginModalRegisterButton'),
              href: t('form_path_request_access_central'),
            }
          : {
              href: Router.getRouteUrl(routes.loginOrRegister),
              text: t('loginModalLoginByEmailButton'),
            }),
        onClick: handleCloseModal,
      },
    },
  }

  return props
}

export default useLoginModal
