import React, { FC } from 'react'

import { MainLayoutProps } from '../../layouts'
import AccessibleTitle, {
  AccessibleTitleProps,
} from '../../components/AccessibleTitle'
import { BreadcrumbProps } from '../../components/Breadcrumb'
import BoxedContainer from '../../components/BoxedContainer'

import * as SC from './styled'

export type PageTemplateProps = MainLayoutProps & {
  accessibleTitleProps?: AccessibleTitleProps
  breadcrumbProps?: BreadcrumbProps
  children?: React.ReactNode
}

const PageTemplate: FC<PageTemplateProps> = ({
  accessibleTitleProps,
  breadcrumbProps,
  children,
  ...layoutProps
}) => {
  return (
    <SC.Layout {...layoutProps}>
      {accessibleTitleProps && <AccessibleTitle {...accessibleTitleProps} />}
      {breadcrumbProps && (
        <BoxedContainer>
          <SC.BreadcrumbElement {...breadcrumbProps} />
        </BoxedContainer>
      )}
      {children}
    </SC.Layout>
  )
}

export default PageTemplate
