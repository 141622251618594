import dynamic from 'next/dynamic'

export const TitleTextImage = dynamic(() => import('../Blocks/TitleTextImage'))
export const Actions = dynamic(() => import('../Blocks/Actions'))
export const InfoBanner = dynamic(() => import('../Blocks/InfoBanner'))
export const Banner = dynamic(() => import('../Blocks/Banner'))
export const OffersSlider = dynamic(() => import('../Blocks/OffersSlider'))
export const BrandsSlider = dynamic(() => import('../Blocks/BrandsSlider'))
export const Categories = dynamic(() => import('../Blocks/Categories'))
export const FormProduct = dynamic(() => import('../Blocks/FormProduct'))
export const BlockUrpsFacts = dynamic(() => import('../Blocks/BlockUrpsFacts'))
export const PublishAds = dynamic(() => import('../Blocks/PublishAds'))
export const FormYoungDoctor = dynamic(
  () => import('../Blocks/FormYoungDoctor')
)
export const HighlightedOffers = dynamic(
  () => import('../Blocks/HighlightedOffers')
)
export const TitleTextButton = dynamic(
  () => import('../Blocks/TitleTextButton')
)
export const ProfilesAdsSliders = dynamic(
  () => import('../Blocks/ProfilesAdsSliders')
)
export const BlocksSideBySide = dynamic(() => import('./BlocksSideBySide'))
export const ImageRightTextLinkLeft = dynamic(
  () => import('./ImageRightTextLinkLeft')
)
export const OffersBlock = dynamic(() => import('./OffersBlock'))
export const FormSearchAds = dynamic(() => import('./FormSearchAds'))
