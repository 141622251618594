import { GetStaticPropsContext, NextPage } from 'next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { initWithLangAndLabels } from '../i18n'
import PageTemplate, { PageTemplateProps } from '../templates/PageTemplate'
import Blocks from '../relay/Blocks'
import { GetStaticPropsReturnType, PageProps } from '../relay/Common/PageProps'
import getConfiguration from '../rest/services/getConfiguration'
import RestApiClient from '../rest/client'
import pageBySlug from '../rest/requests/pages/pageBySlug'
import { Page as PageData } from '../rest/types/Page'
import useFooter from '../relay/Footer/useFooter'
import { default as alertRequest } from '../rest/requests/alert/alert'
import type { Alert } from '../rest/types/Alert'
import SectionWrapper from '../components/SectionWrapper'
import BannerInfo from '../components/BannerInfo'
import useAlertProps from '../relay/Alert/useAlertProps'
import useBreadcrumbProps from '../relay/Page/useBreadcrumbProps'
import { SeoTransformer } from '../relay/Transformers/SeoTransformer'
import Router from '../routes/Router'
import routes from '../routes/definitions'
import { actions, selectors } from '../redux'
import useHeaderBis from '../relay/header/useHeaderBis'
import { BlockComponentsName } from '../relay/Blocks/props'
import getCategories from '../rest/requests/classifieds/getCategories'
import getSpecialties from '../rest/requests/classifieds/getSpecialties'
import {
  ClassifiedCategories,
  ClassifiedSpecialties,
} from '../rest/types/Classified'
import useLoginModal from '../relay/Modals/useLoginModal'
import LoginModal from '../components/LoginModal'

type Props = PageProps & {
  data: PageData
  alert: Alert | null
  specialities?: ClassifiedSpecialties
  categories?: ClassifiedCategories
}

const Page: NextPage<Props> = ({
  data,
  alert,
  configuration,
  specialities,
  categories,
}) => {
  const headerProps = useHeaderBis(
    configuration,
    data?.slug !== 'home' && data.pageType
  )
  const footerProps = useFooter(configuration)
  const alertProps = useAlertProps(alert)
  const breadcrumbProps = useBreadcrumbProps(data)
  const loginModalProps = useLoginModal()
  const isConnected = useSelector(selectors.auth.isConnected)
  const hasAccessToStore = useSelector(selectors.auth.hasAccessToStore)
  const dispatch = useDispatch()
  const [dataUpdated, setDataUpdated] = useState(data)

  const props: PageTemplateProps = {
    headerProps,
    footerProps,
    breadcrumbProps,
  }

  useEffect(() => {
    if (data?.slug === 'onboarding') {
      dispatch(actions.auth.visitOnboarding())
    }
  }, [dispatch, data?.slug])

  useEffect(() => {
    if (isConnected) {
      const isImageRightTextLinkLeft = data?.blocks?.find(
        (b: any) => b.__component === BlockComponentsName.imageRightTextLinkLeft
      )

      if (isImageRightTextLinkLeft) {
        const dataBlocksUpdated = data?.blocks?.filter(function (block) {
          return block.__component !== 'blocks.image-right-text-link-left'
        })
        setDataUpdated((prevState) => {
          return {
            ...prevState,
            blocks: dataBlocksUpdated,
          }
        })
      } else {
        setDataUpdated(data)
      }
    } else {
      setDataUpdated(data)
    }
  }, [data, isConnected])

  return (
    <PageTemplate
      {...props}
      modals={
        !isConnected || (isConnected && !hasAccessToStore) ? (
          <LoginModal {...loginModalProps} />
        ) : (
          <></>
        )
      }
    >
      {data.slug === 'accueil-la-centrale' && alert?.status && (
        <SectionWrapper maxWidth={'Xsmall'}>
          <BannerInfo {...alertProps} />
        </SectionWrapper>
      )}
      {data?.blocks && (
        <Blocks
          blocks={dataUpdated?.blocks ?? []}
          configuration={configuration}
          slug={data?.slug}
          specialities={specialities}
          categories={categories}
        />
      )}
    </PageTemplate>
  )
}

export default Page

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export async function getStaticProps(
  context: GetStaticPropsContext
): GetStaticPropsReturnType<Props> {
  const slug: string =
    '' + Array.isArray(context?.params?.slug) && context?.params?.slug
      ? (context.params.slug as string[]).join('/')
      : (context?.params?.slug as string) ?? ''

  const [rs, rsAlert] = await Promise.all([
    RestApiClient.call(pageBySlug({ slug: slug === '' ? 'home' : slug })),
    ...(slug === 'accueil-la-centrale'
      ? [RestApiClient.call(alertRequest())]
      : []),
  ])

  const data = rs?.data
  const alert = rsAlert?.data ?? {}

  const block = data?.blocks?.find(
    (b: any) => b.__component === BlockComponentsName.formSearchAds
  )
  const withWeeklyOffers = data?.blocks?.some(
    (b: any) => b.__component === BlockComponentsName.highlightedOffers
  )

  const [configuration] = await Promise.all([
    getConfiguration({ withWeeklyOffers }),
  ])

  let specialities = null
  let categories = null

  if (block) {
    const [rsSpecialities, rsCategories] = await Promise.all([
      RestApiClient.call(
        getSpecialties({
          pagination: {
            pageSize: 100,
          },
          sort: ['name'],
        })
      ),
      RestApiClient.call(getCategories()),
    ])
    specialities = rsSpecialities?.data
    categories = rsCategories?.data
  }

  const i18n = initWithLangAndLabels('fr', configuration.translations)

  if (!data) {
    return {
      notFound: true,
      props: {
        i18n,
        configuration,
      },
      revalidate: 30,
    }
  }

  return {
    props: {
      metas: SeoTransformer(
        slug === '/' || !slug
          ? Router.getRouteUrl(routes.home)
          : Router.getRouteUrl(routes.page, context?.params),
        data?.seo
      ),
      data,
      specialities,
      categories,
      alert,
      configuration,
      i18n,
    },
    revalidate: 30,
  }
}
