import React, { FC } from 'react'
import cx from 'classnames'

import { SectionSpacerProps } from '../SectionSpacer'
import { BoxedContainerProps } from '../BoxedContainer'

import * as SC from './styled'

export type SectionWrapperProps = Omit<SectionSpacerProps, 'children'> &
  Omit<BoxedContainerProps, 'children'> & {
    className?: string
    children: React.ReactNode
    withBackgroundColor?: boolean
  }

const SectionWrapper: FC<SectionWrapperProps> = ({
  className,
  children,
  spacing = 'medium',
  withBackgroundColor,
  ...wrapperProps
}) => {
  return (
    <SC.Section
      className={cx('SectionWrapper', className)}
      $withBackgroundColor={withBackgroundColor}
    >
      <SC.Wrapper {...wrapperProps}>
        {spacing ? (
          <SC.Spacer spacing={spacing}>{children}</SC.Spacer>
        ) : (
          children
        )}
      </SC.Wrapper>
    </SC.Section>
  )
}

export default SectionWrapper
