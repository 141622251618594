import React, { FC, useCallback } from 'react'

import {
  dashToCamelCase,
  upperCaseFirstChar,
} from '../../helpers/StringHelpers'

import * as components from './components'
import { BlockProps, BlocksProps, PageBlocksUnion } from './props'

const Blocks: FC<BlocksProps> = ({ blocks, ...otherParams }) => {
  const renderBlock: any = useCallback(
    (block: PageBlocksUnion & { __component: string }, key: number) => {
      const name = upperCaseFirstChar(
        dashToCamelCase(block.__component.replace('blocks.', ''))
      )
      const Block: FC<BlockProps> = (components as any)[name] ?? null

      if (!Block) {
        console.warn('Missing block : ' + name)
        return null
      }

      return (
        <Block
          key={`${block.__component}-${key}`}
          data={block}
          {...otherParams}
        />
      )
    },
    [otherParams]
  )

  return <>{blocks?.map(renderBlock)}</>
}

export default Blocks
