import styled from 'styled-components'

import Link from '../Link'
import { mq, sizes } from '../../theme'

export const BreadcrumbList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.blueSecondaryBis};

  @media ${mq(null, sizes.mobile)} {
    display: none;
  }

  li:not(:last-child) {
    padding-right: 1.2rem;
    position: relative;

    &:after {
      content: '';
      border-right: 1px solid ${({ theme }) => theme.colors.blueSecondaryBis};
      position: absolute;
      height: 50%;
      right: 0;
      top: 25%;
    }
  }

  li:not(:first-child) {
    padding-left: 1.2rem;
  }
  li:not(:last-child) > * {
    ${({ theme }) => theme.textStyles.montserratSm};
  }
  li:last-child > * {
    ${({ theme }) => theme.textStyles.montserratTextBaseBold};
  }
`

export const BreadcrumbListItem = styled.li``

export const Lk = styled(Link)`
  color: ${({ theme }) => theme.colors.blueSecondaryBis};
`
