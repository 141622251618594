import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type SectionSpacerProps = {
  className?: string
  spacing?: 'small' | 'medium' | 'large' | 'big' | 'none'
  children: React.ReactNode
}

const SectionSpacer: FC<SectionSpacerProps> = ({
  className,
  spacing = 'medium',
  children,
}) => {
  return (
    <SC.SectionSpacer
      className={cx('SectionSpacer', className)}
      $spacing={spacing}
    >
      {children}
    </SC.SectionSpacer>
  )
}

export default SectionSpacer
