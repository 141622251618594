import { BannerInfoProps } from '../../components/BannerInfo'
import { Alert } from '../../rest/types/Alert'

const useAlertProps = (alert: Alert | null): BannerInfoProps => {
  return {
    text: alert?.text,
    color: alert?.color,
    link: {
      text: alert?.label,
      href: alert?.url,
      target: alert?.target,
    },
  }
}

export default useAlertProps
